import { tracks } from "../../../mocks/music_thumbnails";
import MusicColumnDouble from "../../Components/MusicColumnDouble";
import Row from "react-bootstrap/Row";
import GoTo from "../../Components/GoTo";

function RecentlyPlayed() {
    // fixme
    const data = tracks;
    const goto = <GoTo target="history" />

    return (
        <>
            <Row><h1>Recently Played</h1></Row>
            <MusicColumnDouble data={data} goto={goto}/>
        </>
    )
}

export default RecentlyPlayed;