import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"

function PlaylistThumbnail() {
    return (
        <Col xs={6} sm={4} md={3} className="my-2">
            <Image thumbnail src="https://i.scdn.co/image/ab67616d0000b2739fb6068078c8525a7f5a6d16" />
        </Col>
    )
}

export default PlaylistThumbnail;