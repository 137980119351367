import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsDiscFill } from "react-icons/bs";

import "./MusicThumbnail.css"
import TextTruncated from "../TextTruncated";
import { Link } from "react-router-dom";

function Title(props) {
    // data
    const {
        ID, name
    } = props.data;

    // maxLength

    // default title length
    let maxLength = 45;
    if (props.maxLength) {
        maxLength = props.maxLength;
    }

    const dotCount = 2;

    return (
        <Row>
            <Col className="no-padding">
                <Link to={`/track/${ID}`} className="th-link title">
                    <BsDiscFill className="me-2"/>
                    <TextTruncated text={name} maxLength={maxLength} dotCount={dotCount} />
                </Link>
            </Col>
        </Row>
    )
}

export default Title;