import Container from "react-bootstrap/Container"

export function Download() {
  return (
    <Container>
      "download"
    </Container>
  );
}

export default Download;
