import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "react-router-dom";
import TextTruncated from "../TextTruncated";

import "./MusicThumbnail.css"

function Artist(props) {
    // data
    // maxLength

    const {
        ID, name
    } = props.data;

    // default maxLength
    let maxLength = 20;
    if (props.maxLength) {
        maxLength = props.maxLength;
    }

    // default artist
    let artist = "defaultArtist"
    if (name) {
        artist = name
    }

    return (
        <Row>
            <Col className="no-padding">
                <Link to={`/artist/${ID}`} className="th-link artist">
                    <TextTruncated text={artist} maxLength={maxLength} dotCount={2} />
                </Link>
            </Col>
        </Row>
    )
}

export default Artist;