import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import PlayButton from "./PlayButton"
import InfosLg from "./InfosLg"
import InfosShortMd from "./InfosShortMd"

import "./MusicThumbnail.css";
import InfosShortSm from "./InfosShortSm"
import InfosShortXs from "./InfosShortXs"
import { useState } from "react"

function MusicThumbnail(props) {
    
    const [ bgColor, setBgColor ] = useState("rgba(0, 0, 0, 0)");
    const [ rgba, setRgba ] = useState({
        r: 0,
        g: 0,
        b: 0,
        opacity: 0,
    })

    function setBgColorFromRgba(newRgba) {

        let newBgColor = `rgba(${newRgba.r}, ${newRgba.g}, ${newRgba.b}, ${newRgba.opacity})`;
        setBgColor(newBgColor);
    }

    function onVibrant(color) {
        let newRgba = {
            r: color[0],
            g: color[1],
            b: color[2],
            opacity: 1
        }
        setBgColorFromRgba(newRgba);
        setRgba(newRgba);
    }

    function onMouseEnter() {
        setBgColorFromRgba({
            r: rgba.r - 50,
            g: rgba.g - 50,
            b: rgba.b - 50,
            opacity: 1
        });
    }

    function onMouseLeave() {
        setBgColorFromRgba({
            r: rgba.r,
            g: rgba.g,
            b: rgba.b,
            opacity: 1
        });
    }

    
    props = {
        onVibrant: onVibrant,            
        ...props,
    }

    return (
        <Container>
            <Row
                style={{backgroundColor: bgColor}}
                xs="auto"
                className="
                    music-thumbnail
                    justify-content-between
                    align-items-center
                    my-2"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>

                {/* lg thumbnail */}
                <Col xs={10} className="d-none d-lg-block">
                    <InfosLg {...props}/>
                </Col>

                {/* md thumbail */}
                <Col xs={10} className="d-none d-md-block d-lg-none">
                    <InfosShortMd {...props} />
                </Col>

                {/* sm thumbnail */}
                <Col xs={10} className="d-none d-sm-block d-md-none">
                    <InfosShortSm {...props} />
                </Col>

                {/* xs thumbnail */}
                <Col xs={10} className="d-sm-none">
                    <InfosShortXs {...props} />
                </Col>

                <Col xs={2} className="play-button-container">
                    <PlayButton />
                </Col>
            </Row>
        </Container>
    );
}

export default MusicThumbnail;