function Message(props) {
    let textClass = "muted"
    if (props.textClass) {
        textClass = props.textClass;
    }

    return (
        <span className={`text-${textClass}`}>
            {props.msg}
        </span>
    )
}

export default Message;