import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

function Album() {
    const { id } = useParams();
    return (
        <Container>
            Album {id}
        </Container>
    )
}

export default Album;