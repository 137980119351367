import { BsList } from "react-icons/bs";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../Logo";

import "./MyNavbar.css"

function Toggler() {

    return (
        <Navbar.Toggle className="my-navbar-toggler m-2" aria-controls="navbar">

            <div style={{position: "relative"}}>

                <Logo className="brand toggler-logo"/>

                <div style={{
                    position: "absolute",
                    top: "21px",
                    left: "20px"
                }}>
                    <BsList fontSize={"1em"} className="text-white" />
                </div>
            </div>

        </Navbar.Toggle>
    )
}

export default Toggler;