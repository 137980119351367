import Playlists from "./Playlists";

import Container from "react-bootstrap/Container";
import RecentlyPlayed from "./RecentlyPlayed";

export function Home() {
  return (
    <Container>

      <Playlists />
      
      <RecentlyPlayed />

      {/* <RecentlyAdded /> */}

    </Container>
  )
}

export default Home;
