export const tracks = [
    {
        "ID": "7zB3ZhA5v33pA4rCdKKGqq",
        "name": "High",
        "spotifyUrl": "https://open.spotify.com/track/7zB3ZhA5v33pA4rCdKKGqq",
        "album": {
            "ID": "1Pl9C2X0IiwBUyRqAwpbV1",
            "name": "Postcards From Heaven",
            "spotifyUrl": "https://open.spotify.com/album/1Pl9C2X0IiwBUyRqAwpbV1",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b27315a65231417b72b14b720a55",
            "releaseDate": "1997"
        },
        "artists": [
            {
                "ID": "6edGSAX5dVpeJVwu1Q0NwJ",
                "name": "Lighthouse Family",
                "spotifyUrl": "https://open.spotify.com/artist/6edGSAX5dVpeJVwu1Q0NwJ",
                "imageUrl": "https://i.scdn.co/image/ab6761610000e5eb0a972998322326f51309ce2d",
                "genres": [
                    "europop",
                    "new wave pop",
                    "pop rock",
                    "soft rock"
                ]
            }
        ],
        "durationMs": 310333,
        "previewUrl": "https://p.scdn.co/mp3-preview/043fa9fa6c629011e18cfea0bea7d02f53eb8c5c?cid=50112d9ef3054086a1f89575bd0617c6",
        "popularity": 54
    },
    {
        "ID": "1HdoZ6Ql7nx5HfQrlVYtoM",
        "name": "Lifted",
        "spotifyUrl": "https://open.spotify.com/track/1HdoZ6Ql7nx5HfQrlVYtoM",
        "album": {
            "ID": "4BOrXWCcpRDXIdREVNrK3q",
            "name": "Ocean Drive",
            "spotifyUrl": "https://open.spotify.com/album/4BOrXWCcpRDXIdREVNrK3q",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b273bde4adaefc81b0502e081e82",
            "releaseDate": "1995-01-01"
        },
        "artists": [
            {
                "ID": "6edGSAX5dVpeJVwu1Q0NwJ",
                "name": "Lighthouse Family",
                "spotifyUrl": "https://open.spotify.com/artist/6edGSAX5dVpeJVwu1Q0NwJ",
                "imageUrl": "https://i.scdn.co/image/ab6761610000e5eb0a972998322326f51309ce2d",
                "genres": [
                    "europop",
                    "new wave pop",
                    "pop rock",
                    "soft rock"
                ]
            }
        ],
        "durationMs": 271866,
        "previewUrl": "https://p.scdn.co/mp3-preview/14b68f8672dcd4429e971ec4980cad9742892bf0?cid=50112d9ef3054086a1f89575bd0617c6",
        "popularity": 58
    },
    {
        "ID": "2WUVQQAsWvScEdBVilRYaR",
        "name": "The Lighthouse Keeper",
        "spotifyUrl": "https://open.spotify.com/track/2WUVQQAsWvScEdBVilRYaR",
        "album": {
            "ID": "30Nf68hbXjDvoacY0K5fFB",
            "name": "The Lighthouse Keeper",
            "spotifyUrl": "https://open.spotify.com/album/30Nf68hbXjDvoacY0K5fFB",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b2734e805619b7db9cea747a4908",
            "releaseDate": "2020-11-20"
        },
        "artists": [
            {
                "ID": "2wY79sveU1sp5g7SokKOiI",
                "name": "Sam Smith",
                "spotifyUrl": "https://open.spotify.com/artist/2wY79sveU1sp5g7SokKOiI",
                "imageUrl": "https://i.scdn.co/image/ab6761610000e5eb10ca40ea0b0b5082dba0ff75",
                "genres": [
                    "dance pop",
                    "pop",
                    "uk pop"
                ]
            }
        ],
        "durationMs": 247493,
        "previewUrl": "https://p.scdn.co/mp3-preview/d712ebb761042858bb5c0ed77c147a33ee728cdf?cid=50112d9ef3054086a1f89575bd0617c6",
        "popularity": 68
    },
    {
        "ID": "435rQGMojc5C6xJbtjeynu",
        "name": "Ain't No Sunshine",
        "spotifyUrl": "https://open.spotify.com/track/435rQGMojc5C6xJbtjeynu",
        "album": {
            "ID": "2uktxjF2ee3eSUr615kI6C",
            "name": "Greatest Hits",
            "spotifyUrl": "https://open.spotify.com/album/2uktxjF2ee3eSUr615kI6C",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b2730aeb0bca84163f2b38eb07b8",
            "releaseDate": "2002-01-01"
        },
        "artists": [
            {
                "ID": "6edGSAX5dVpeJVwu1Q0NwJ",
                "name": "Lighthouse Family",
                "spotifyUrl": "https://open.spotify.com/artist/6edGSAX5dVpeJVwu1Q0NwJ",
                "imageUrl": "https://i.scdn.co/image/ab6761610000e5eb0a972998322326f51309ce2d",
                "genres": [
                    "europop",
                    "new wave pop",
                    "pop rock",
                    "soft rock"
                ]
            }
        ],
        "durationMs": 221333,
        "previewUrl": "https://p.scdn.co/mp3-preview/138f21024d07ff87cf1757088c734237040f663a?cid=50112d9ef3054086a1f89575bd0617c6",
        "popularity": 59
    },
    {
        "ID": "6jHBlKpIKNflU51QlsJx3v",
        "name": "Ocean Drive",
        "spotifyUrl": "https://open.spotify.com/track/6jHBlKpIKNflU51QlsJx3v",
        "album": {
            "ID": "4BOrXWCcpRDXIdREVNrK3q",
            "name": "Ocean Drive",
            "spotifyUrl": "https://open.spotify.com/album/4BOrXWCcpRDXIdREVNrK3q",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b273bde4adaefc81b0502e081e82",
            "releaseDate": "1995-01-01"
        },
        "artists": [
            {
                "ID": "6edGSAX5dVpeJVwu1Q0NwJ",
                "name": "Lighthouse Family",
                "spotifyUrl": "https://open.spotify.com/artist/6edGSAX5dVpeJVwu1Q0NwJ",
                "imageUrl": "https://i.scdn.co/image/ab6761610000e5eb0a972998322326f51309ce2d",
                "genres": [
                    "europop",
                    "new wave pop",
                    "pop rock",
                    "soft rock"
                ]
            }
        ],
        "durationMs": 228426,
        "previewUrl": "https://p.scdn.co/mp3-preview/f1b921f40f0c5fac1087a1643f30e365320fa47e?cid=50112d9ef3054086a1f89575bd0617c6",
        "popularity": 58
    },
    {
        "ID": "27FaSj75qyieqD3drJZh6i",
        "name": "Lost In Space",
        "spotifyUrl": "https://open.spotify.com/track/27FaSj75qyieqD3drJZh6i",
        "album": {
            "ID": "1Pl9C2X0IiwBUyRqAwpbV1",
            "name": "Postcards From Heaven",
            "spotifyUrl": "https://open.spotify.com/album/1Pl9C2X0IiwBUyRqAwpbV1",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b27315a65231417b72b14b720a55",
            "releaseDate": "1997"
        },
        "artists": [
            {
                "ID": "6edGSAX5dVpeJVwu1Q0NwJ",
                "name": "Lighthouse Family",
                "spotifyUrl": "https://open.spotify.com/artist/6edGSAX5dVpeJVwu1Q0NwJ",
                "imageUrl": "https://i.scdn.co/image/ab6761610000e5eb0a972998322326f51309ce2d",
                "genres": [
                    "europop",
                    "new wave pop",
                    "pop rock",
                    "soft rock"
                ]
            }
        ],
        "durationMs": 322706,
        "previewUrl": "https://p.scdn.co/mp3-preview/f3743d9aade855968a6ae5ebd49b6e7a2651d2c7?cid=50112d9ef3054086a1f89575bd0617c6",
        "popularity": 34
    },
    {
        "ID": "5tQf6esOwPVDA2qO84nr2a",
        "name": "THE LIGHTHOUSE FAMILY",
        "spotifyUrl": "https://open.spotify.com/track/5tQf6esOwPVDA2qO84nr2a",
        "album": {
            "ID": "2ewXbyjBXiVCHF5edXHT6g",
            "name": "Hindsight is 20/21",
            "spotifyUrl": "https://open.spotify.com/album/2ewXbyjBXiVCHF5edXHT6g",
            "imageUrl": "https://i.scdn.co/image/ab67616d0000b273df5a81fc0c5cfcce32d39b9a",
            "releaseDate": "2022-09-02"
        },
        "artists": [
            {
                "ID": "3zjMl5AgeEVHlJkVRefdIR",
                "name": "PREDECEASED",
                "spotifyUrl": "https://open.spotify.com/artist/3zjMl5AgeEVHlJkVRefdIR",
                "imageUrl": "https://i.scdn.co/image/ab67616d0000b273df5a81fc0c5cfcce32d39b9a"
            }
        ],
        "durationMs": 292800,
        "previewUrl": "https://p.scdn.co/mp3-preview/7b568703d9059eec639383a9af0d91f2cdd806f0?cid=50112d9ef3054086a1f89575bd0617c6"
    }
]