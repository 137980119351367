import { ProgressBar } from "react-bootstrap";

import "./Status.css"

export default function StatusBar(props) {   
    let variant = "primary";
    if (props.variant) {
        variant = props.variant
    }

    let animated = false;
    if (props.animated) {
        animated = true;
    }

    return (
        <ProgressBar className="search-status-bar" variant={variant} animated={animated} now={props.progress}/>
    )
}