import InfosShort from "./InfosShort";

function InfosShortMd(props) {
    props = {
        ...props,

        titleMaxLength: 18,
        albumHeight: "48px"
    }
    return (
        <InfosShort {...props} />
    )
}

export default InfosShortMd;