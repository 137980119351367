import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../Logo";

function Brand() {

    return (
        <LinkContainer to="/">
            <Nav.Link>
                <Navbar.Brand>
                    
                    <Logo className="brand" />
                    
                </Navbar.Brand>
            </Nav.Link>
        </LinkContainer>
    )
}

export default Brand;