import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function LoginModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}

            backdrop="static"
            keyboard={false}
            centered

            contentClassName="bg-dark"
        >
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    modal body
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={props.onLogin}>
                        Login
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}

export default LoginModal;