import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

function GoTo(props) {
    return (
        <Container style={{
            textAlign: "end"
        }}>
            <Button>
                go to {props.target}
            </Button>
        </Container>
    )
}

export default GoTo;