import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import Brand from "./Brand"

import "./MyNavbar.css"

function NavLeft() {
    return (
        <Nav className="me-auto my-nav">
            
            <Brand />
            
            <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
            </LinkContainer>
        
            <LinkContainer to="/download">
                <Nav.Link>Download</Nav.Link>
            </LinkContainer>
        
            <LinkContainer to="/search">
                <Nav.Link>
                    Search
                </Nav.Link>
            </LinkContainer>
            
        </Nav>
    )
}

export default NavLeft;