import InfosShort from "./InfosShort";

function InfosShortSm(props) {
    props = {
        ...props,
        
        titleMaxLength: 9,
        albumHeight: "48px"
    }
    return (
        <InfosShort {...props}/>
    )
}

export default InfosShortSm;