import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MusicThumbnail from "./MusicThumbnail/MusicThumbnail";

function MusicColumnDouble(props) {

    const data = props.data;
    const className = props.className;

    let half = Math.ceil(data.length / 2);
    let dataFirstColumn = data.slice(0, half);
    let dataSecondColumn = data.slice(half, data.length);

    return (
        <Row className={className}>
            <Col sm={6}>
                {
                    dataFirstColumn.map((item) => {
                        return <MusicThumbnail
                            key={item.ID}
                            data={item}
                        />
                    })
                }
            </Col>
            <Col sm={6}>
                {
                    dataSecondColumn.map((item) => {
                        return <MusicThumbnail
                            key={item.ID}
                            data={item}
                        />
                    })
                }
                {props.goto}
            </Col>
        </Row>
    )
}

export default MusicColumnDouble;