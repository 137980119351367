function TextTruncated(props) {
    // maxLength
    // dotCount
    // text
    let formattedText;
    if (props.text.length <= props.maxLength + props.dotCount) {
        formattedText = props.text
    } else {
        formattedText = props.text.slice(0, props.maxLength) + ".".repeat(props.dotCount)
    }

    return (
        <>
            {formattedText}
        </>
    )
}

export default TextTruncated;