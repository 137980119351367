import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import Album from "./Album"
import Artist from "./Artist"
import Title from "./Title"

import "./MusicThumbnail.css"
import LikeButton from "./LikeButton"

function InfosLg(props) {
    // data
    const {
        ID, name, album, artists
    } = props.data;

    // onVibrant

    const titleMaxLength = 20
    const artistMaxLength = 20;
    const albumHeight = "64px"

    return (
        <Row className="align-items-center">
            <Col lg={3} className="no-padding">
                <Album
                    data={album}
                    height={albumHeight} 
                    onVibrant={props.onVibrant}
                />
            </Col>
            <Col lg={8}>
                {/* // fixme */}
                <Artist data={artists[0]} maxLength={artistMaxLength}/>
                <Title data={{ID, name}} maxLength={titleMaxLength}/>
            </Col>
            <Col lg={1}>
                <LikeButton />
            </Col>
        </Row>
    )
}

export default InfosLg;