import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

function Artist() {
    const { id } = useParams();

    return (
        <Container>
            Artist {id}
        </Container>
    )
}

export default Artist;