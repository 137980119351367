import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

function Track() {
    const { id } = useParams();

    return (
        <Container>
            Track {id}
        </Container>
    )
}

export default Track;