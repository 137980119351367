import Spinner from "react-bootstrap/Spinner";

export default function Typing() {
    return (
        <>
            <Spinner animation="grow" size="sm" className="mx-1" />
            <Spinner animation="grow" size="sm" className="mx-1" style={{animationDelay: "0.25s"}} />
            <Spinner animation="grow" size="sm" className="mx-1" style={{animationDelay: "0.5s"}}/>
        </>
    )
}