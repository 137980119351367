import InfosShort from "./InfosShort";

function InfosShortXs(props) {
    props = {
        ...props,

        titleMaxLength: 12,
        albumHeight: "48px"
    }
    return (
        <InfosShort {...props} />
    )
}

export default InfosShortXs;