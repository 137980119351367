import Container from "react-bootstrap/Container";

import "./Status.css"
import Message from "./Message";

import Typing from "./Typing";
import StatusBar from "./StatusBar";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Status(props) {

    const status = props.status;
    const msg = props.statusMessage;

    let [ progress, setProgress ] = useState(100);
    let [ progressVariant, setProgressVariant ] = useState("primary");
    let [ animated, setAnimated ] = useState(false);

    let statusOutput = null;

    function setFullPrimary() {
        if (progress !== 100) {
            setProgress(100);
        }

        if (progressVariant !== "primary") {
            setProgressVariant("primary");
        }

        if (!animated) {
            setAnimated(true);
        }
    }

    function setFullSuccess() {
        if (progress !== 100) {
            setProgress(100);
        }

        if (progressVariant !== "success") {
            setProgressVariant("success");
        }

        if (animated) {
            setAnimated(false);
        }
    }

    function setFullDanger() {
        if (progress !== 100) {
            setProgress(100);
        }

        if (progressVariant !== "danger") {
            setProgressVariant("danger");
        }
        
        if (animated) {
            setAnimated(false);
        }
    }

    function setEmptyPrimary() {
        if (progress !== 0) {
            setProgress(0);
        }

        if (progressVariant !== "primary") {
            setProgressVariant("primary");
        }

        if (!animated) {
            setAnimated(true);
        }
    }
    
    if (status === "typing") {
        setEmptyPrimary();
        statusOutput = <Typing />

    } else if (status === "searching") {
        setFullPrimary();
        statusOutput = <Message msg={msg} />

    } else if (status === "searched") {
        setFullSuccess();
        statusOutput = <Message msg={msg} />

    } else if (status === "error") {
        setFullDanger();
        statusOutput = <Message msg={msg} textClass="danger"/>

    }

    return (
        <>
            <Row className="justify-content-center">
                <Col xs={11}>
                    <Container className="mt-2">
                        <StatusBar progress={progress} variant={progressVariant} animated={animated}/>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Container id="search-status" className="mt-2">
                    {statusOutput}
                </Container>
            </Row>
        </>
    )
}

export default Status;