import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from './NotFound/NotFound.js';
import Layout from './Layout/Layout.js'

import Search from './Pages/Search/Search.js'
import Home from './Pages/Home/Home.js'
import Download from './Pages/Download/Download.js'
import Album from "./Pages/Album/Album.js";
import Artist from "./Pages/Artist/Artist.js";
import Track from "./Pages/Track/Track.js";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="download" element={<Download />} />
          <Route path="search" element={ <Search /> } />

          <Route path="album/:id" element={<Album />} />
          <Route path="artist/:id" element={<Artist />} />
          <Route path="track/:id" element={<Track />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router;
