import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container'

import './App.css';
import Router from './Router/Router';

function App() {
  return (
    <Container fluid className="App">
           
      <Router />  
          
    </Container>  
  );
}

export default App;
