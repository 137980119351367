import { BsSearch } from "react-icons/bs"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import "./Search.css"
import { useEffect, useState } from "react";
import axios from "axios";

function SearchBar(props) {

    // fixme
    let host = process.env.REACT_APP_API_HOST;
    console.log("host: ", host);

    let query = props.query;
    if (!query) {
        query = "";
    }

    // used to fill the input
    let [ searchQuery, setSearchQuery ] = useState(query);
    let [ abortController, setAbortController ] = useState(null);
    let [ currentTimeoutID, setCurrentTimeoutID ] = useState(null);

    const MIN_CHARACTERS_COUNT = 3
    const SEARCH_DELAY = 2000

    function search(newQuery) {
        if (newQuery.length <= MIN_CHARACTERS_COUNT) {
            props.onSearchingInvalid(`Require at least ${MIN_CHARACTERS_COUNT} characters`)
            return
        }

        // checks passed
        props.onSearching(newQuery);

        // mock
        // setTimeout(() => {
        //     props.onSearched([])
        // }, 3000);
        // return;

        // fixme
        let newAbortController = new AbortController()
        setAbortController(newAbortController);
        axios.get(`${host}/music-researcher/search?q=${newQuery}`, {
            signal: newAbortController.signal,
        }).then((response) => {
            props.onSearched(response.data.tracks);
            setAbortController(null);
        })
    }

    function cancelCountdown() {
        // cancel current search countdowns
        if (currentTimeoutID) {
            clearTimeout(currentTimeoutID);
        }
    }

    function cancelHTTPCall() {
        // cancel current searches
        if (abortController) {
            abortController.abort();
            setAbortController(null);
        }
    }

    function onChange(e) {
        // update parent status
        props.onTyping();

        cancelCountdown();
        cancelHTTPCall();

        let newQuery = e.target.value;

        // update input value
        setSearchQuery(newQuery);

        // starts the countdown
        let newCurrentTimeoutID = setTimeout(() => {
            search(newQuery);
        }, SEARCH_DELAY)
        setCurrentTimeoutID(newCurrentTimeoutID)
    }

    // run once, when the component mounts
    useEffect(() => {
        // case when page loaded and query already filled
        if (query && query !== "") {
            search(query)
        }
    
    // empty dependency array so that the component only runs once, and not when a props is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSubmit() {
        cancelCountdown();
        cancelHTTPCall();

        search(searchQuery);
    }

    return (
        <InputGroup id="search-bar-group">
            <Form.Floating>
                <Form.Control
                    className="bg-dark ps-4"
                    id="search-bar"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-button"

                    onChange={onChange}
                    value={searchQuery}
                />
                <label className="ps-4" htmlFor="search-bar">Search</label>
            </Form.Floating>
            <Button variant="dark" id="search-button" type="submit" onClick={onSubmit}>
                <BsSearch />
            </Button>
        </InputGroup>
    )

}

export default SearchBar;