import MusicColumnDouble from "../../Components/MusicColumnDouble";

import "./Search.css"

function Results(props) {

    const results = props.results;
    const status = props.status;

    let className = ""
    if (status === "searching") {
        console.log("faded")
        className = "faded"
    }

    if (!results) {
        return null;
    }
    return (
        <MusicColumnDouble className={className} data={results} />
    )
}

export default Results;