import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import LoginModal from "./LoginModal";

import "./MyNavbar.css"

function NavRight() {

    const [active, setActive] = useState("");
    const [show, setShow] = useState(false);

    const hide = () => {
        setActive("");
        setShow(false);
    }

    const click = () => {
        setActive("active")
        setShow(true);
    }

    const login = () => {
        hide();
    }

    return (
        <>
            <Nav className="my-nav me-3">
                <span type="link" onClick={click}>
                    <i className={`bi bi-box-arrow-in-right login ${active}`}></i>
                </span>
            </Nav>

            <LoginModal show={show} onHide={hide} onLogin={login}/>
        </>
    )
}

export default NavRight;