import CDN from "../../Utils/CDN";

function Logo(props) {

    let className = ""
    if (props.className) {
        className = props.className;
    }



    return (
        <img
            className={className}

            src={CDN.URL("icon.jpeg")}
            width="60"
            height="60"
            alt="logo"
        />
    )
}

export default Logo;