import PlaylistThumbnail from "../../Components/PlaylistThumbnail";
import Row from "react-bootstrap/Row";

function Playlists() {
    return (
        <Row>
            <PlaylistThumbnail />
            <PlaylistThumbnail />
            <PlaylistThumbnail />
        </Row>
    )
}

export default Playlists;