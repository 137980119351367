import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Album from "./Album"
import Title from "./Title";

import "./MusicThumbnail.css"

function InfosShort(props) {
    // data
    const {
        ID, name, album
    } = props.data;

    // onVibrant
    
    // albumHeight
    // titleMaxLength
    
    return (
        <Row className="align-items-center">
            <Col xs={4} sm={4} md={3} className="no-padding">
                <Album
                    data={album}
                    height={props.albumHeight}
                    onVibrant={props.onVibrant}
                />
            </Col>
            <Col xs={8} sm={8} md={9}>
                <Title data={{ID, name}} maxLength={props.titleMaxLength}/>
            </Col>
        </Row>

    )
}

export default InfosShort;
