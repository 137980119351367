import Image from "react-bootstrap/Image"
import Vibrant from "node-vibrant/dist/vibrant"

import "./MusicThumbnail.css"
import { Link } from "react-router-dom";

function Album(props) {

    // data
    const {
        ID, imageUrl
    } = props.data;

    // height
    // onVibrant

    // default album height
    let height = "64px"
    if (props.height) {
        height = props.height;
    }

    function onImageLoad() {
        Vibrant.from(imageUrl).getPalette(function(err, palette) {
            if (err) {
                throw err;
            }
    
            let vibrantColor = palette.DarkMuted.rgb;
            props.onVibrant(vibrantColor);
        });
    }
        
    return (
        <Link to={`/album/${ID}`}>
            <Image
                style={{
                    height: height
                }}
                className="album rounded"
                src={imageUrl}

                onLoad={() => onImageLoad()}
            />
        </Link>
    )
}

export default Album;