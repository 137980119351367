import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import NavLeft from "./NavLeft";
import NavRight from "./NavRight";

import "./MyNavbar.css"
import Toggler from "./Toggler";

function MyNavbar() {
    return (
        <Navbar
            collapseOnSelect
            expand="md" // collapsed when small than medium size
            bg="dark" // dark background
            className="navbar-dark my-navbar" // navbar-dark to handle text color
        >
            <Container fluid>

                {/* only visible when navbar collapse */}
                <Toggler />
                
                <Navbar.Offcanvas
                    id="navbar"
                    aria-labelledby="navbar-title"
                    placement="start"
                    className="text-bg-dark"
                >
                    <Offcanvas.Header closeButton closeVariant="white"/>

                    <Offcanvas.Body> {/* the navbar expanded and collapsed */}

                        <NavLeft />
                        <NavRight />

                    </Offcanvas.Body>

                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default MyNavbar;