import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchBar from "./SearchBar";

import { useState } from "react";
import Status from "./Status/Status";
import Results from "./Results";
import { useSearchParams } from "react-router-dom";

function Search() {

  let [ status, setStatus ] = useState("empty");
  let [ statusMessage, setStatusMessage ] = useState("");
  let [ results, setResults ] = useState(null);
  let [ searchParams, setSearchParams ] = useSearchParams();

  function onTyping() {
    setStatus("typing")
    setStatusMessage("Typing...")
  }

  function onSearching(newQuery) {
    setStatus("searching")
    setStatusMessage("Searching...")
    setSearchParams({
      q: newQuery,
    })
  }

  function onSearchingInvalid(msg) {
    setStatus("error")
    setStatusMessage(msg)
  }

  function onSearched(results) {
    setStatus("searched")
    setStatusMessage(`Found ${results.length} matching tracks`)
    setResults(results)
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={8}>
          <SearchBar
            query={searchParams.get("q")}
            onTyping={onTyping}
            onSearching={onSearching}
            onSearched={onSearched}
            onSearchingInvalid={onSearchingInvalid}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={8}>
          <Status status={status} statusMessage={statusMessage} />
        </Col>
      </Row>
      <Row className="justify-content-center">
          <Results status={status} results={results} />
      </Row>
    </Container>
  );
}

export default Search;