import { Outlet } from 'react-router-dom';
import Row from 'react-bootstrap/Row'
import { Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import MyNavbar from './Navbar/MyNavbar';

function Layout() {
  return (
    <Row className="justify-content-center">
      <Col>
        <Container style={{
          maxWidth: "960px"
        }}>

            <MyNavbar />

            <Outlet />
            
        </Container>
      </Col>
    </Row>
  );
}

export default Layout;